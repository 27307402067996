body {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0; }

.right {
  float: right; }

.text-center {
  text-align: center; }

.center {
  margin: 0 auto; }

.clearfix, #top, #content aside ul.float, .nav {
  zoom: 1; }
  .clearfix:before, #top:before, #content aside ul.float:before, .nav:before, .clearfix:after, #top:after, #content aside ul.float:after, .nav:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .clearfix:after, #top:after, #content aside ul.float:after, .nav:after {
    clear: both; }

img.b {
  border: 1px solid #eee; }

.inner, #top {
  margin: 0 auto;
  width: 960px;
  max-width: 960px; }

a {
  color: #222;
  text-decoration: none; }
  a:hover {
    color: #FF4136; }

.base_button, #button-jodd, #content article .button {
  margin-bottom: 20px; }
  .base_button a, #button-jodd a, #content article .button a {
    background-color: #111;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    color: #fff;
    font-family: "Fjalla One", Helvetica, sans-serif;
    padding: 30px 40px;
    text-align: center;
    font-size: 26px;
    display: block;
    width: 200px;
    text-decoration: none; }
    .base_button a:hover, #button-jodd a:hover, #content article .button a:hover {
      color: #111;
      background-color: #ddd; }
    .base_button a div.sub, #button-jodd a div.sub, #content article .button a div.sub {
      font-family: "Open Sans Condensed", Helvetica, sans-serif;
      font-size: 20px;
      margin-top: 4px; }

/* header */
#social {
  margin-top: 15px;
  margin-right: 90px;
  float: right; }

#social a {
  font-size: 30px;
  margin-right: 10px;
  position: relative; }
  #social a:hover {
    color: #fff; }

#social a span {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  display: none;
  position: absolute;
  top: -28px;
  right: 0;
  padding: 3px 6px;
  background: #454545;
  color: #fff;
  font-size: 14px;
  font-family: "Open Sans Condensed", Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  white-space: nowrap; }
  #social a span:after {
    position: absolute;
    top: 18px;
    right: 6px;
    content: " ";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #454545; }

#social a:hover span {
  display: block; }

#pplane {
  z-index: 101;
  position: absolute;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

#intro {
  background-color: #FF4136;
  background-size: 100% auto;
  padding-top: 6px;
  border-bottom: 40px solid #FFDC00; }
  #intro header {
    background-color: #fff; }

#shout {
  font-size: 24px;
  text-align: center;
  color: #111;
  background-color: #FFDC00;
  padding: 0 0 20px 0; }
  #shout strong {
    font-weight: bold; }
  #shout p {
    padding-bottom: 4px; }

#top nav {
  float: right;
  margin-right: 40px; }
  #top nav a {
    font-family: "Fjalla One", Helvetica, sans-serif;
    display: block;
    padding: 30px 5px;
    float: left; }

#logo {
  float: left;
  background-color: #FF4136;
  color: #000;
  padding: 4px 10px;
  font-size: 20px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  display: block; }
  #logo i {
    width: 20px; }
  #logo span {
    padding-left: 6px;
    position: relative;
    top: -8px; }

#title {
  color: #fff;
  padding: 40px 40px 60px 40px; }
  #title h1 {
    font-family: "Lato", Helvetica, sans-serif;
    font-weight: normal;
    font-size: 80px;
    margin-bottom: 20px; }
  #title h2 {
    font-family: "Lato", Helvetica, sans-serif;
    font-size: 30px; }

#button-jodd {
  float: right;
  margin-top: 60px;
  margin-right: 100px;
  margin-bottom: 0px; }
  #button-jodd a {
    color: white;
    background-color: #111;
    margin-bottom: 20px; }

/* content */
#content article #github_edit {
  margin: 40px 0 20px 0;
  text-align: center;
  font-size: 14px; }
  #content article #github_edit a {
    color: #aaa; }
    #content article #github_edit a:hover {
      background-color: #fff;
      text-decoration: underline; }
  #content article #github_edit i {
    font-size: 20px;
    position: relative;
    top: 3px;
    padding-right: 4px; }

#main {
  margin: 40px 0;
  color: #444; }
  #main h2 {
    text-align: center;
    font-family: "Fjalla One", Helvetica, sans-serif;
    font-size: 30px;
    margin-bottom: 6px; }
  #main .under {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px; }
    #main .under a {
      border-bottom: 1px dotted black; }
      #main .under a:hover {
        border-bottom: none; }

#pplane2 {
  z-index: 101;
  left: 60px;
  top: -90px;
  position: relative; }

#signature h2 {
  text-align: center;
  font-family: "Fjalla One", Helvetica, sans-serif;
  font-size: 22px;
  margin: 10px 0; }

#needhelp {
  margin: 0 0 80px 0;
  padding: 30px 0;
  color: #444;
  background-color: #FFDC00; }
  #needhelp h2 {
    text-align: center;
    font-family: "Fjalla One", Helvetica, sans-serif;
    font-size: 22px;
    margin: 10px 0; }
  #needhelp p {
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    margin: 0 10%;
    padding-bottom: 20px; }
    #needhelp p b {
      font-weight: bold; }

#content article > * {
  padding-left: 40px;
  padding-right: 20px; }

#main h2 {
  margin-top: 60px 30px 10px 30px;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

#content {
  margin: 0;
  color: #444;
  position: relative; }
  #content #social {
    margin-top: -40px;
    margin-right: 20px; }
    #content #social a {
      color: #111; }
      #content #social a:hover {
        color: #fff; }
  #content .inner, #content #top {
    background-color: #f9f9f9;
    position: relative;
    margin-top: -200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px; }
  #content article {
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    width: auto;
    margin-right: 280px;
    border-right: 1px solid #f4f3f1;
    padding: 20px 0;
    /* main */ }
    #content article a {
      padding: 0 4px;
      color: #FF4136; }
      #content article a:hover {
        text-decoration: none;
        color: #fff;
        background-color: #FF4136;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px; }
    #content article a.javadoc {
      float: right; }
      #content article a.javadoc:hover {
        background-color: #fff; }
    #content article hr {
      margin: 10px 10px 10px 0; }
    #content article h1 {
      font-size: 50px;
      font-family: "Fjalla One", Helvetica, sans-serif;
      margin-bottom: 30px;
      letter-spacing: -3px; }
    #content article h2 {
      font-size: 36px;
      margin: 70px 0 20px 0;
      letter-spacing: -1px;
      font-weight: bold; }
    #content article h3 {
      font-size: 22px;
      margin: 40px 0 6px 0;
      font-weight: bold; }
    #content article p, #content article ul, #content article ol {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 18px; }
    #content article p.attn {
      padding: 10px 40px 10px 10px !important;
      color: #000;
      border-left: 30px solid #FFDC00;
      border-top: 2px solid #FFDC00;
      border-bottom: 2px solid #FFDC00; }
    #content article strong {
      font-weight: bold; }
    #content article em {
      font-style: italic; }
    #content article em.jodd {
      font-style: normal;
      font-weight: bold;
      padding: 0 1px 0 1px; }
    #content article ul li {
      margin-left: 50px;
      list-style: disc; }
    #content article ol li {
      margin-left: 50px;
      list-style: decimal; }
    #content article ul.doc-all {
      margin-left: 20px;
      margin-top: 0;
      overflow: hidden;
      position: relative;
      top: -16px; }
    #content article ul.doc-all li {
      margin-left: 3px;
      list-style: none;
      float: left;
      font-size: 0.9em; }
    #content article ul.doc-all li:after {
      content: "  |";
      color: #999; }
    #content article li.doc-more {
      margin-top: 6px; }
    #content article blockquote p {
      font-style: italic;
      margin-left: 20px;
      padding-left: 10px;
      border-left: 4px solid #f0f0f0;
      margin-right: 30px; }
  #content aside {
    position: absolute;
    top: 40px;
    right: 0;
    width: 260px;
    color: #888;
    display: block; }
    #content aside h2 {
      font-size: 26px;
      letter-spacing: -1px;
      font-weight: bold;
      margin-bottom: 6px; }
    #content aside h2.doc {
      color: #666; }
    #content aside h3 {
      font-size: 18px;
      letter-spacing: -1px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 6px; }
    #content aside ul.float li {
      float: left; }
    #content aside li {
      list-style: none;
      margin-bottom: 6px;
      margin-right: 6px; }
    #content aside a {
      color: #111; }
      #content aside a:hover {
        text-decoration: none;
        color: #FF4136; }
    #content aside ul.doc-all {
      margin-bottom: 30px; }
    #content aside li.doc-this {
      background-color: #f0f0f0;
      padding: 4px 4px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      font-weight: bold;
      margin-left: 0;
      margin-right: 0; }
    #content aside li.doc-more {
      margin-top: 6px; }
    #content aside #xtoc {
      padding-left: 20px; }
    #content aside .fixed {
      position: fixed !important;
      top: 0;
      background-color: #fff;
      padding: 15px 0 20px 20px;
      margin: 0 0 30px 0;
      width: 240px;
      z-index: 111; }
    #content aside #toc {
      background-color: #fff;
      padding: 15px 0 20px 0;
      margin: 0 0 30px 0;
      position: relative;
      left: -22px; }
      #content aside #toc h2, #content aside #toc h3 {
        font-size: 1.1em;
        text-transform: uppercase; }
      #content aside #toc a {
        font-size: 0.9em;
        color: #666; }
        #content aside #toc a:hover {
          color: #FF4136; }

#search {
  margin: 20px 0; }

#search input {
  width: 180px; }

input[type="search"] {
  -webkit-appearance: searchfield;
  box-sizing: border-box;
  padding: 1px;
  background-color: white;
  border: 2px inset; }

#search button {
  position: absolute;
  top: -500px; }

#title-doc {
  padding: 100px 0; }

code {
  font-family: "Inconsolata", Courier, monospace;
  font-size: 17px;
  color: #444;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 2px 4px; }

div.highlighter-coderay {
  padding: 0 !important; }

pre {
  overflow: auto;
  color: #444;
  padding: 10px 0 10px 10px;
  margin: 0 0 10px 0;
  font-family: "Inconsolata", Courier, monospace;
  font-size: 16px;
  line-height: 18px;
  background-color: #f5f5f5; }

pre code {
  background: none;
  border: none;
  padding: 0; }

.badges {
  width: 820px;
  margin: 0 auto;
  display: grid;
  grid-auto-columns: 200px;
  grid-template-areas: "a a a a";
  grid-gap: 10px;
  margin-bottom: 40px; }

.badges-small {
  width: 820px;
  margin: 0 auto;
  display: grid;
  grid-auto-columns: 130px;
  grid-template-areas: "a a a a a a";
  grid-gap: 10px;
  margin-bottom: 40px; }

.badge-frame, .badge {
  font-family: "Lato", Helvetica, sans-serif;
  width: 180px;
  text-align: center;
  margin: 10px;
  padding: 40px 0 20px 0; }

.badge {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border: 1px solid #ddd;
  background-color: #efefef;
  -webkit-box-shadow: 0px 10px 0px -1px #ddd;
  -moz-box-shadow: 0px 10px 0px -1px #ddd;
  box-shadow: 0px 10px 0px -1px #ddd; }
  .badge .desc {
    margin: 0 6px;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 17px; }
  .badge:hover {
    background-color: #f5f5f5;
    color: #444; }

.badge.push {
  grid-column-start: 2; }

.topic-head {
  background-color: #ee7985;
  font-size: 46px;
  letter-spacing: -2px;
  line-height: 100px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  text-decoration: none; }

.badges-small .badge {
  width: 120px;
  padding-top: 0; }

.badges-small .topic-head {
  font-size: 30px;
  letter-spacing: -1px; }

.badges-small .desc {
  font-size: 14px; }

.bg-col-1 {
  background-color: #AAAAAA; }

.bg-col-2 {
  background-color: #AAAAAA; }

.bg-col-3 {
  background-color: #AAAAAA; }

.radial-out {
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
  transform: translateZ(0); }

.radial-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -50px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 0.4s ease-out; }

.radial-out:hover:before,
.radial-out:focus:before {
  transform: scale(2); }

.radial-out .topic-icon {
  transform: scale(1);
  transition: transform 0.4s ease-out; }

.radial-out:hover .topic-icon,
.radial-out:focus .topic-icon {
  transform: scale(1.5); }

.radial-out .topic-title {
  top: 0;
  position: relative;
  transition: all 0.4s ease-out; }

.radial-out:hover .topic-title,
.radial-out:focus .topic-title {
  top: -10px;
  color: black; }

.bg-col1 {
  background-color: #c6d0d5; }

.bg-col2 {
  background-color: #e6e7d1; }

.bg-col3 {
  background-color: #f5e0d1; }

.bg-col4 {
  background-color: #edccaf; }

var {
  background-color: #2b526a;
  color: #fff;
  padding: 0 6px 2px 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-family: "Inconsolata", Courier, monospace;
  letter-spacing: -1px;
  font-size: 14px; }

var.dep {
  background-color: #6c90a9; }

var.dep-opt {
  background-color: #909090; }

var.lib {
  background-color: #FF4136; }

/* prev-next */
.nav {
  margin: 100px 0 20px 0; }
  .nav a {
    display: block;
    font-size: 18px;
    letter-spacing: -1px;
    padding: 10px 0 10px 20px; }
  .nav a.nav-next {
    float: right; }
  .nav a.nav-prev {
    float: left; }

/* release */
dl.release {
  margin: 20px 10px 30px 10px;
  border-top: 1px dotted #9F9F9F; }
  dl.release dt {
    float: left;
    width: 80px;
    text-align: right;
    padding: 2px 6px;
    line-height: 22px;
    margin-top: 6px;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 11px;
    color: #383838;
    margin-right: 10px;
    text-shadow: 1px 1px 0 #FFFFFF;
    text-transform: uppercase;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.2) inset;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px; }
  dl.release dt.tag-new {
    background: url(/gfx/tag-new.png) #FFEDCF 10px 4px no-repeat;
    border-right: solid 4px #FFA300; }
  dl.release dt.tag-fixed {
    background: url(/gfx/tag-fixed.png) #F0FFE1 10px 4px no-repeat;
    border-right: solid 4px #5BA622; }
  dl.release dt.tag-changed {
    background: url(/gfx/tag-changed.png) #EBE1FF 10px 4px no-repeat;
    border-right: solid 4px #8106E0; }
  dl.release dd {
    border-bottom: 1px dotted #9F9F9F;
    min-height: 25px;
    padding: 10px 20px 10px 10px;
    font-size: 12px;
    margin-left: 10px;
    line-height: 14px; }
    dl.release dd code, dl.release dd var {
      font-size: 12px; }

/* tables */
table {
  border: 2px solid #D5DCE8;
  margin: 10px 0 10px 40px; }
  table td {
    padding: 4px 12px;
    border: 1px solid white;
    font-family: "Source Sans Pro", Helvetica, sans-serif; }

thead td, table th {
  background-color: #7E8AA2;
  color: white;
  font-weight: bold;
  padding: 4px; }

tbody td.high {
  background-color: #EEF0F4;
  font-size: 14px; }

tbody tr.r0, tbody tr:nth-child(even) {
  background-color: #fff; }

tbody tr.r1, tbody tr:nth-child(odd) {
  background-color: #F6F7FA; }

tbody tr:hover {
  background-color: #FFF1E4; }

tbody td:hover {
  background-color: #FFE3CA; }

/* footer */
#ohloh {
  margin-top: 40px; }

footer {
  margin: 60px 0 0 0;
  color: #f5f5f5;
  color: #444; }

#support {
  background-color: #ddd;
  padding: 60px 10px;
  color: #111; }
  #support a {
    color: #111;
    text-decoration: underline; }

#overview {
  text-align: center;
  color: #888;
  background-color: #111;
  padding: 100px 10px;
  size: 12px; }

#share {
  font-size: 30px;
  padding: 20px 0 40px 0; }
  #share a {
    color: #aaa;
    margin: 0 20px; }
    #share a:hover {
      color: #fff; }

/* error */
#error {
  text-align: center; }
  #error h1 {
    font-size: 80px;
    margin-bottom: 40px; }
  #error h2 {
    font-size: 40px;
    margin-bottom: 40px; }
  #error p {
    font-size: 20px; }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation: rotateInUpRight 1s;
  animation: rotateInUpRight 1s; }

/** MEDIUM **/
@media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
  #button-jodd {
    margin-right: 10px; }
  #social {
    margin-right: 0; }
  #title h2 {
    font-size: 25px; }
  .badges {
    width: 610px;
    grid-auto-columns: 33%;
    grid-template-areas: "a a a"; }
  .badges-small {
    width: 610px;
    grid-auto-columns: 25%;
    grid-template-areas: "a a a a"; }
  #top {
    width: 640px;
    max-width: 640px; }
  .inner, #top {
    width: 640px;
    max-width: 640px; }
  #twitter {
    display: none; }
  #intro #pplane {
    width: 64px;
    height: 64px;
    left: 20px;
    top: -40px; }
  #content #toc {
    display: none; }
  #content aside {
    position: static;
    width: auto;
    padding: 20px;
    padding-top: 40px !important; }
  #content article {
    padding-right: 20px;
    margin-right: 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px; } }

/** SMALL**/
@media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
  #button-jodd {
    float: none;
    margin: 0; }
    #button-jodd a {
      margin: 10px auto; }
  #title {
    padding: 10px;
    text-align: center; }
    #title h1 {
      display: none; }
    #title h2 {
      font-size: 20px; }
  #twitter {
    display: none; }
  #social {
    float: none; }
  #content #social {
    display: none; }
  .badges {
    width: inherit;
    margin: 0;
    grid-auto-columns: 100%;
    grid-template-areas: "a"; }
  .badge.push {
    grid-column-start: 1; }
  .badges-small {
    width: inherit;
    margin: 0;
    grid-auto-columns: 50%;
    grid-template-areas: "a a "; }
    .badges-small .badge {
      width: 80%; }
  #pplane {
    display: none; }
  #pplane2 {
    display: none; }
  #github_edit {
    display: none; }
  #share a {
    margin: 0 10px; }
  .badge {
    float: left;
    width: inherit;
    margin: 0 10px 10px 10px;
    padding: 0 0 10px 0; }
    .badge span {
      font-size: 18px; }
    .badge .topic-head {
      font-size: 30px;
      padding: 10px 0;
      line-height: 20px; }
    .badge .desc {
      text-align: left;
      padding: 10px 20px 0 10px; }
    .badge .circle, .badge .square, .badge .hexagon {
      margin-left: 0;
      margin-bottom: 0;
      float: left;
      font-size: 30px;
      width: 80px;
      line-height: 80px; }
  #logo {
    float: none;
    text-align: center; }
  #top {
    width: auto;
    max-width: none; }
    #top nav {
      float: none;
      margin-right: 0; }
      #top nav a {
        text-align: center;
        float: none;
        margin: 0;
        padding: 8px 0;
        border-bottom: 1px dotted #999;
        color: #333;
        font-size: 14px; }
        #top nav a:hover {
          background-color: #f0f0f0; }
  #title-doc {
    padding: 6px 0; }
  #shout .inner, #shout #top, #main .inner, #main #top, #content .inner, #content #top, #intro .inner, #intro #top, footer .inner, footer #top, #needhelp .inner, #needhelp #top {
    width: auto;
    max-width: none;
    margin-top: 0; }
  #shout {
    font-size: 15px; }
  #intro {
    background-image: none;
    border-bottom-width: 10px;
    padding-bottom: 10px; }
  #main h2 {
    font-size: 24px; }
  #main .under {
    font-size: 14px; } }

/* search */
body.search-overlay {
  overflow: hidden; }

body.search-overlay:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9001;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #0d0d0d;
  background-color: rgba(0, 0, 0, 0.6);
  content: ''; }

.dosearch {
  display: none; }

.search-wrapper {
  position: absolute;
  top: 50px;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  *zoom: 1; }

.search-wrapper:before, .search-wrapper:after {
  display: table;
  line-height: 0;
  content: ""; }

.search-wrapper:after {
  clear: both; }

@media only screen and (min-width: 48em) {
  .search-wrapper {
    top: 100px; } }

.search-form {
  position: relative;
  top: 0;
  left: -200px;
  z-index: 9002;
  width: 100%;
  opacity: 0;
  transition: all 200ms 100ms cubic-bezier(0, 0.6, 0.4, 1); }

.search-form.active {
  top: 0;
  left: 0;
  opacity: 1; }

.search-form.hidden {
  display: none; }

.search-form ::-webkit-input-placeholder {
  font-size: 32px;
  font-size: 2rem; }

.search-form i {
  position: absolute;
  top: .6rem;
  right: 20%;
  z-index: 1;
  display: block;
  color: #fff;
  text-align: center;
  cursor: pointer; }

.search-form .search-field {
  width: 80%;
  font-size: 32px;
  font-size: 2rem;
  color: #fff;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  box-shadow: none;
  background-clip: padding-box;
  -webkit-appearance: none; }

.search-form .search-field:focus {
  outline: 0;
  box-shadow: none; }

.search-form .search-field::-webkit-search-decoration,
.search-form .search-field::-webkit-search-cancel-button,
.search-form .search-field::-webkit-search-results-button,
.search-form .search-field::-webkit-search-results-decoration {
  display: none; }

.search-form .search-list {
  position: absolute;
  width: 70%;
  margin-top: 30px;
  margin-left: 40px; }

.search-form .search-list li {
  padding: 6px; }

.search-form .search-list a {
  color: #eee;
  font-size: 1.2em; }

.search-form .search-list a:hover {
  color: #fff;
  font-weight: bold; }

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 100; }

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9; }

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center; }

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px; }

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent; }

.ribbon-top-left::before {
  top: 0;
  right: 0; }

.ribbon-top-left::after {
  bottom: 0;
  left: 0; }

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg); }

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px; }

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent; }

.ribbon-top-right::before {
  top: 0;
  left: 0; }

.ribbon-top-right::after {
  bottom: 0;
  right: 0; }

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg); }
